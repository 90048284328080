<template>
  <v-container fluid class="pa-7">
    <overlay :loading="loading"></overlay>
    <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
    <v-row>
      <v-col cols="12" md="10">
        <v-text-field outlined dense v-model="name" label="Species" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field outlined dense v-model="genus" label="Genus" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field outlined dense v-model="family" label="Family" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field outlined dense v-model="length" label="Length" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-select :items="allLengthType" v-model="length_type" label="Length Type" outlined dense
          hide-details="auto"></v-select>
      </v-col>
      <v-col cols="12" md="10">
        <v-select :items="allStatus" v-model="status" label="Status" outlined dense hide-details="auto"></v-select>
      </v-col>
      <v-col cols="12" md="10">
        <v-file-input outlined dense v-model="files" chips show-size label="Images" prepend-icon=""
          prepend-inner-icon="mdi-image"></v-file-input>
      </v-col>
      <v-col cols="12" md="10" class="d-flex justify-end">
        <v-btn v-if="!updateProcess" :loading="loading" :disabled="loading" color="primary" @click="onClickUpload">
          Save
          <v-icon right dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
        <v-btn v-else :loading="loading" :disabled="loading" color="primary" @click="onClickUpdate">
          Update
          <v-icon right dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import MyPageHeader from "@/components/PageHeaders.vue";
  import Overlay from "@/components/Overlay.vue";
  export default {
    components: {
      MyPageHeader,
      Overlay
    },
    data() {
      return {
        loader: null,
        title: "Fish Identity - Input Data",
        description: "Input Data Fish Identity",
        breadcrumbs: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Fish Identity",
            disabled: false,
            href: "/species",
          },
          {
            text: "Input",
            disabled: true,
            href: "/species/upload",
          },
        ],
        loading: false,
        snackColor: "success",
        snackMessage: "",
        showSnack: false,
        species_id: null,
        name: "",
        genus: "",
        family: "",
        status: "",
        length: "",
        length_type: "",
        files: null,
        updateProcess: false,
        allStatus: ['Archive', 'Unarchive'],
        allLengthType: ['TL', 'FL', 'SL']
      }
    },
    created() {
      if (this.$route.query.item) {
        const item = this.$route.query.item;
        this.species_id = item.id;
        this.name = item.name;
        this.genus = item.genus;
        this.family = item.family;
        this.status = item.status;
        this.length = item.size;
        this.length_type = item.sizeType;
        this.files = item.image;
        this.updateProcess = true;
      }
    },
    methods: {
      onClickUpload() {
        this.$http.post('/api/v1/species/create', {
          'name': this.name,
          'genus': this.genus,
          'family': this.family,
          'status': this.status,
          'length': this.length,
          'length_type': this.length_type,
          'image': this.files
        }, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }).then(() => {
          // handle success
          this.$router.push({ name: 'speciesIndex' })
        })
          .catch((error) => {
            // handle error
            this.loading = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      async onClickUpdate() {
        this.loading = true;
        await this.$http.put('/api/v1/species/update', {
          'name': this.name,
          'genus': this.genus,
          'family': this.family,
          'status': this.status,
          'length': this.length,
          'length_type': this.length_type,
          'image': this.files
        }, {
          params: {
            species_id: this.species_id,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }).then(() => {
          // handle success
          this.$router.push({ name: 'speciesIndex' })
          this.loading = false;
        })
          .catch((error) => {
            // handle error
            this.loading = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
    }
  }
</script>