<template>
    <v-container fluid class="fill-height" v-if="this.data != null">
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-img class="white--text align-start" contain :src="baseImageUrl + '/'+ this.data.data.image">
                        <v-card-title> {{ this.data.name }}</v-card-title>
                    </v-img>
                    <v-card-text class="text--primary">
                        <div>Created by ...</div>
                        <div>Created by {{ this.data.data.createdAt }}</div>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

    export default {
       
        data() {
            return {
                
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Created At', value: 'createdAt' },
                    { text: 'Updated At', value: 'updatedAt' },
                ],
                data: null,
                speciesId: null,
                baseImageUrl: process.env.VUE_APP_HOST
            }
        },
        methods: {
            loadData() {
                this.$http.get('/api/v1/species', { params: { id: this.speciesId } })
                    .then((response) => {
                        console.log("berhasil")
                        // handle success
                        this.data = response.data
                        console.log(this.data)
                        console.log(this.baseImageUrl + this.data.data.image)
                    })
                    .catch((error) => {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }
        },
        created() {
            this.speciesId = this.$route.query.id;
        },
        watch: {
            speciesId(newData, oldData) {
                if (newData != oldData) {
                    this.loadData();
                }
            }
        }
    }
</script>