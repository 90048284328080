<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-row>
            <v-col class="mb-2 d-flex justify-end">
                <my-button :color="'primary'" :onClick="onClickNewSpecies" :tooltip="'Input new data'"
                    :label="'New'"></my-button>
            </v-col>
        </v-row>
        <my-table :data="data" :headers="headers" :onClickArchiveItem="onClickArchiveItem"
            :onClickEditItem="onClickEditItem" :editable="editable" :showDetail="showDetail"
            :showDetailFunction="showDetailFunction"></my-table>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
            <template>
                <v-card>
                    <v-toolbar class="gradient" dark>{{ speciesDetail.name }}</v-toolbar>
                    <v-img class="white--text align-start" contain
                        :src="baseImageUrl + '/'+ this.speciesDetail.image"></v-img>
                    <v-card-text>
                        <div class="text-subtitle-1">Genus: {{ speciesDetail.genus }}</div>
                        <div class="text-subtitle-1">Family: {{ speciesDetail.family }}</div>
                        <div class="text-subtitle-1">Length: {{ speciesDetail.size }}</div>
                    </v-card-text>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    import MyButton from "@/components/Button.vue";
    import MyTable from "@/components/DataTable.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            MyButton,
            MyTable
        },
        data() {
            return {
                title: "Fish Identity - Daftar Data",
                description: "Keseluruhan Data Fish Identity yang Tersimpan",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "Fish Identity",
                        disabled: true,
                        href: "/species",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                editable: true,
                showDetail: true,
                dialog: false,
                speciesDetail: {},
                baseImageUrl: process.env.VUE_APP_HOST,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Genus', value: 'genus' },
                    { text: 'Family', value: 'family' },
                    { text: 'Status', value: 'status' },
                    { text: 'Size', value: 'size' },
                    { text: 'Size Type', value: 'sizeType' },
                    { text: 'Actions', value: 'actions', sortable: false }
                ],
                data: []
            }
        },
        methods: {
            onClickNewSpecies() {
                console.log("new species")
                this.$router.push({ name: 'speciesUpload' })
            },
            onClickEditItem(item) {
                this.$router.push({ name: 'speciesUpload', query: { item: item } })
            },
            async onClickArchiveItem(v) {
                this.loading = true;
                let status;
                v.status === 'Archive' ? status = 'Unarchive' : status = 'Archive';
                await this.$http.put('/api/v1/species/status/update', {
                    'status': status
                }, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        species_id: v.id,
                    },
                })
                    .then(() => {
                        // handle success
                        this.loading = false;
                        this.loadData();
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            loadData() {
                this.loading = true;
                this.$http.get('/api/v1/species/list')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            showDetailFunction(item) {
                this.speciesDetail = item;
                this.dialog = true;
            },
        },
        created() {
            this.loadData();
        }
    }
</script>