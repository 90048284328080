<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <!-- <v-row>
            <v-col class="mb-2 d-flex justify-end">
                <my-button disabled :color="'primary'" :onClick="onClickNewSpecies" :tooltip="'Input new data'"
                    :label="'New'"></my-button>
            </v-col>
        </v-row> -->
        <!-- <my-table :data="data" :headers="headers" :onClickArchiveItem="onClickArchiveItem"
            :onClickEditItem="onClickEditItem"></my-table> -->
        <v-data-table hide-default-footer :headers="headers" :items="data" :items-per-page="100" class="elevation-1">
            <template v-slot:item.count="{ item }">
                <div>
                    {{ item.images.length }}
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>
                    <v-list>
                        <v-list-item @click="showDetailFunction(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-image-text
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Detail</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <snackbar :color="snackColor" :message="snackMessage" :show="showSnack" :btnCloseClick="btnCloseClick">
        </snackbar>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="700px">
            <template>
                <v-card>
                    <v-toolbar class="gradient" dark>{{ dataTrainDetail.species + ' : ' +
                        dataTrainDetail.size}}</v-toolbar>
                    <v-row class="ma-6" v-if="dataTrainDetail.images && dataTrainDetail.images.length > 0">
                        <v-col cols="4" md="4" v-for="img in dataTrainDetail.images" :key="img">
                            <v-img class="white--text align-start" contain
                                :src="baseImageUrl + '/images/train/'+ dataTrainDetail.species + ' - ' + dataTrainDetail.size + '/' + img "></v-img>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" class="text-center ma-4">
                            <span class="subtitle-1">No Image Available</span>
                        </v-col>
                    </v-row>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    // import MyButton from "@/components/Button.vue";
    // import MyTable from "@/components/DataTable.vue";
    import Snackbar from "@/components/Snackbar.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            // MyButton,
            // MyTable,
            Snackbar,
        },
        data() {
            return {
                title: "Data Training - Daftar Data",
                description: "Keseluruhan Data Training yang Tersimpan",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "Data Training",
                        disabled: true,
                        href: "/training",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                dialog: false,
                headers: [
                    {
                        text: 'Species',
                        align: 'start',
                        sortable: false,
                        value: 'species',
                    },
                    { text: 'Size', value: 'size' },
                    { text: 'Image Count', value: 'count' },
                    // { text: 'Created At', value: 'createdAt' },
                    // { text: 'Progress', value: 'processStatus' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                data: [],
                dataTrainDetail: [],
                baseImageUrl: process.env.VUE_APP_HOST,
            }
        },
        methods: {
            onClickNewSpecies() {
                this.$router.push({ name: 'trainingUpload' })
            },
            onClickEditItem() {
                this.showSnack = true;
                this.snackColor = "error";
                this.snackMessage = "Feature not Available";
            },
            async onClickArchiveItem(v) {
                this.loading = true;
                let status;
                v.status === 'Archive' ? status = 'Unarchive' : status = 'Archive';
                await this.$http.put('/api/v1/training/update/status', {
                    'status': status
                }, {
                    params: {
                        training_id: v.id,
                    },
                })
                    .then(() => {
                        // handle success
                        this.loading = false;
                        this.loadData();
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            btnCloseClick() {
                this.showSnack = false;
            },
            loadData() {
                this.loading = true;
                // this.$http.get('api/v1/training/list')
                this.$http.get('api/v1/training/image/list')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            showDetailFunction(item) {
                this.dataTrainDetail = item;
                this.dialog = true;
            },
        },
        created() {
            this.loadData();
        }
    }
</script>