<template>
  <v-container fluid class="pa-7">
    <overlay :loading="loading"></overlay>
    <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
    <v-row>
      <v-col cols="12" md="10">
        <v-file-input outlined dense v-model="files" prepend-icon="" prepend-inner-icon="mdi-image" chips show-size
          multiple label="Images"></v-file-input>
      </v-col>
      <v-col cols="12" md="10" class="d-flex justify-end">
        <v-btn :loading="loading" :disabled="loading" color="primary" class="ma-2" @click="onClickUpload">
          Upload
          <v-icon right dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import MyPageHeader from "@/components/PageHeaders.vue";
  import Overlay from "@/components/Overlay.vue";
  export default {
    components: {
      MyPageHeader,
      Overlay,
    },
    data() {
      return {
        loader: null,
        title: "Onboard - Input Data",
        description: "Input Data Onboard",
        breadcrumbs: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Onboard",
            disabled: false,
            href: "/prediction",
          },
          {
            text: "Input",
            disabled: true,
            href: "/prediction/form",
          },
        ],
        loading: false,
        snackColor: "success",
        snackMessage: "",
        showSnack: false,
        label: "",
        files: []
      }
    },
    methods: {
      onClickUpload() {
        this.$http.postForm('/event_uploads', {
          'label': this.label,
          'images': this.files
        });
      },
    }
  }
</script>