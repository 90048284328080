<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-row>
            <v-col class="mb-2 d-flex justify-end">
                <my-button :color="'primary'" :onClick="exportExcel" :tooltip="'Export to Excel'"
                    :icon="'mdi-microsoft-excel'"></my-button>
            </v-col>
        </v-row>
        <table>
            <thead>
                <tr>
                    <th>Vessel</th>
                    <th style="text-align: center;">Data Result</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in formattedData">
                    <tr :key="index">
                        <td>{{ item.vessel_name }}</td>
                        <td>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Capture At</th>
                                        <th>Size</th>
                                        <th>Species</th>
                                        <th>Location</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(prediction, predictionIndex) in item.predictions">
                                        <tr :key="predictionIndex">
                                            <td>{{ prediction.capture_at }}</td>
                                            <td>{{ prediction.size }}</td>
                                            <td>{{ prediction.species }}</td>
                                            <td>{{ prediction.location }}</td>
                                            <td>{{ prediction.amount }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>


    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    // import MyTable from "@/components/DataTable.vue";
    import MyButton from "@/components/Button.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            // MyTable,
            MyButton
        },
        computed: {
            formattedData() {
                return this.data.map((item) => {
                    return {
                        vessel_name: item.vessel_name,
                        predictions: item.predictions,
                    };
                });
            },
        },
        data() {
            return {
                title: "Catching Data By Vessel",
                description: "",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "Catching Data By Vessel",
                        disabled: true,
                        href: "/onboard/report",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                editable: false,
                predictionDetail: {},
                showDetail: true,
                dialog: false,
                confirmDialogShow: false,
                baseImageUrl: process.env.VUE_APP_HOST,
                species: null,
                size: null,
                data: [],
                speciesData: [],
            }
        },
        methods: {
            loadReport() {
                this.loading = true;
                this.$http.get('/api/v1/prediction/report')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            async exportExcel() {
                this.loading = true;
                await this.$http
                    .get('/api/v1/prediction/export', { responseType: 'blob' })
                    .then((response) => {
                        this.loading = false;
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Catching data by Vessel.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = 'error';
                        this.snackMessage = error.response.data.detail;
                    });
            },
            showDetailFunction(item) {
                this.predictionDetail = item;
                this.dialog = true;
            },
            formatDateTime(datetime) {
                // Implement your own date/time formatting logic here
                // For example, using the 'date-fns' library:
                // import { format } from 'date-fns';
                // return format(new Date(datetime), 'yyyy-MM-dd HH:mm:ss');
                return datetime;
            },
        },
        created() {
            this.loadReport();
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
        border-collapse: collapse;
    }

    table th,
    table td {
        padding: 10px;
        text-align: left;
        border: 1px solid #ccc;
    }

    table th {
        background-color: #f2f2f2;
        font-weight: bold;
    }

    table tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }
</style>