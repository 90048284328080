<template>
    <v-list shaped>
        <v-list-item link>
            <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-group :value="false" no-action v-for="(menu, i) in routes" :key="i">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item v-for="(submenu, i) in menu.children" :key="i" link @click="linkTo(submenu)"
                :disabled="submenu.disable">
                <v-list-item-icon>
                    <v-icon>{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ submenu.name }}</v-list-item-title>
            </v-list-item>
        </v-list-group>
        <v-list>
            <v-list-item v-for="(item, i) in dotMenu" :key="i" @click="menuOnClick(item.action)">

                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-list>
</template>
<script>
    export default {
        name: "myMenu",
        props: {
            routes: {
                type: Array,
                required: true,
            },
            linkTo: Function,
            menuOnClick: Function,
            dotMenu: Array,
        },
    };
</script>