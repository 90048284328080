<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-row>
            <v-col class="mb-2 d-flex justify-end">
                <my-button :color="'primary'" :onClick="onClickNewUser" :tooltip="'Input new data'"
                    :label="'New'"></my-button>
            </v-col>
        </v-row>
        <my-table :data="data" :headers="headers"></my-table>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    import MyButton from "@/components/Button.vue";
    import MyTable from "@/components/DataTable.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            MyButton,
            MyTable
        },
        data() {
            return {
                title: "User - Daftar Data",
                description: "Keseluruhan Data User yang Tersimpan",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "User",
                        disabled: true,
                        href: "/user",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Email', value: 'email' },
                    { text: 'Created At', value: 'createdAt' }
                ],
                data: []
            }
        },
        methods: {
            onClickNewUser() {
                this.$router.push({ name: 'userInput' })
            },
            loadData() {
                this.loading = true;
                this.$http.get('api/v1/user/list')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            }
        },
        created() {
            this.loadData();
        }
    }
</script>