<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-row>
            <v-col class="mb-2 d-flex justify-end">
                <my-button :color="'primary'" :onClick="onClickNewVessel" :tooltip="'Input new data'"
                    :label="'New'"></my-button>
            </v-col>
        </v-row>
        <my-table :data="data" :headers="headers" :onClickArchiveItem="onClickArchiveItem"
            :onClickEditItem="onClickEditItem" :editable="editable"></my-table>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    import MyButton from "@/components/Button.vue";
    import MyTable from "@/components/DataTable.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            MyButton,
            MyTable
        },
        data() {
            return {
                title: "Vessel - Daftar Data",
                description: "Keseluruhan Data Vessel yang Tersimpan",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "Vessel",
                        disabled: true,
                        href: "/vessel",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                editable: true,
                headers: [
                    {
                        text: 'Nama',
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    { text: 'Ukuran kapal', value: 'size' },
                    { text: 'Registration port', value: 'registrationPort' },
                    { text: 'Landing port', value: 'landingPort' },
                    { text: 'Tahun pembuatan', value: 'productionYear' },
                    { text: 'Nama & asal pemilik kapal', value: 'shipOwnerOrigin' },
                    { text: 'WPP operasi', value: 'wppOperation' },
                    { text: 'Alat tangkap', value: 'catchingTool' },
                    { text: 'Mesin (jenis & ukuran)', value: 'machineType' },
                    { text: 'Kapasitas palka', value: 'palkaCapacity' },
                    { text: 'Jumlah ABK', value: 'abkNumber' },
                    { text: 'Actions', value: 'actions', sortable: false }
                ],
                data: []
            }
        },
        methods: {
            onClickNewVessel() {
                console.log("new vessel")
                this.$router.push({ name: 'vesselForm' })
            },
            onClickEditItem(item) {
                this.$router.push({ name: 'vesselForm', query: { item: item } })
            },
            async onClickArchiveItem(v) {
                this.loading = true;
                let status;
                v.status === 'Archive' ? status = 'Unarchive' : status = 'Archive';
                await this.$http.put('/api/v1/vessel/update/status', {
                    'status': status
                }, {
                    params: {
                        vessel_id: v.id,
                    },
                })
                    .then(() => {
                        // handle success
                        this.loading = false;
                        this.loadData();
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            loadData() {
                this.loading = true;
                this.$http.get('api/v1/vessel/list')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
        },
        created() {
            this.loadData();
        }
    }
</script>