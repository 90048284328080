<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="name" label="Nama" required :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="size" label="Ukuran" required
                        :rules="nameRules"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="registrationPort" label="Registration Port"
                        required></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="landingPort" label="Landing Port" required></v-text-field>
                </v-col>
            </v-row>


            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="productionYear" label="Tahun pembuatan" required
                        :rules="mustNumber"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="shipOwnerOrigin"
                        label="Nama & asal pemilik kapal"></v-text-field>
                </v-col>
            </v-row>


            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="wppOperation" label="WPP Operation" required
                        :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="catchingTool" label="Alat Tangkap" required
                        :rules="nameRules"></v-text-field>
                </v-col>
            </v-row>


            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="machineType" label="Mesin (jenis & ukuran)" required
                        :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="palkaCapacity" label="Kapasitas palka" required
                        :rules="mustNumber"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-2 pl-2">
                    <v-text-field outlined dense v-model="abkNumber" label="Jumlah ABK" required
                        :rules="mustNumber"></v-text-field>
                </v-col>
                <v-col cols=" 12" md="6" class="pr-2 pl-2">
                    <v-select :items="allStatus" v-model="status" label="Status" outlined dense
                        hide-details="auto"></v-select>
                </v-col>
            </v-row>


            <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn v-if="!updateProcess" color="primary" class="mr-4" @click="validate">
                        Save
                    </v-btn>
                    <v-btn v-else :loading="loading" :disabled="loading" class="mr-4" color="primary"
                        @click="onClickUpdate">
                        Update
                    </v-btn>

                    <v-btn color="btnCancel" class="mr-4" @click="resetValidation">
                        Cancel
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    import { nameRules, mustNumber } from "@/etc/rules";
    export default {
        components: {
            MyPageHeader,
            Overlay
        },
        data() {
            return {
                valid: true,
                vessel_id: '',
                name: '',
                size: '',
                registrationPort: '',
                landingPort: '',
                productionYear: '',
                shipOwnerOrigin: '',
                wppOperation: '',
                catchingTool: '',
                machineType: '',
                palkaCapacity: '',
                abkNumber: '',
                status: 'Unarchive',
                machineTypeAndSize: '',
                title: "Vessel - Input Data",
                description: "Input Data Vessel",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "Vessel",
                        disabled: false,
                        href: "/vessel",
                    },
                    {
                        text: "Input",
                        disabled: true,
                        href: "/vessel/new",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                updateProcess: false,
                allStatus: ['Archive', 'Unarchive'],
                nameRules: nameRules,
                mustNumber: mustNumber,
            }
        },
        created() {
            if (this.$route.query.item) {
                const item = this.$route.query.item;
                this.vessel_id = item.id
                this.name = item.name
                this.size = item.size
                this.registrationPort = item.registrationPort
                this.landingPort = item.landingPort
                this.productionYear = item.productionYear
                this.shipOwnerOrigin = item.shipOwnerOrigin
                this.wppOperation = item.wppOperation
                this.catchingTool = item.catchingTool
                this.machineType = item.machineType
                this.palkaCapacity = item.palkaCapacity
                this.abkNumber = item.abkNumber
                this.machineType = item.machineType
                this.updateProcess = true;
            }
        },
        methods: {
            async save() {
                this.loading = true;
                await this.$http.post('api/v1/vessel/create', {
                    'id': 0,
                    'name': this.name,
                    'size': this.size,
                    'registrationPort': this.registrationPort,
                    'landingPort': this.landingPort,
                    'productionYear': this.productionYear,
                    'shipOwnerOrigin': this.shipOwnerOrigin,
                    'wppOperation': this.wppOperation,
                    'catchingTool': this.catchingTool,
                    'machineType': this.machineType,
                    'palkaCapacity': this.palkaCapacity,
                    'abkNumber': this.abkNumber,
                    'status': 'Unarchive',
                    'createdAt': '',
                    'modifiedAt': ''
                })
                    .then((response) => {
                        console.log(response);
                        this.$router.push({ name: 'vesselIndex' })
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    });
            },
            async onClickUpdate() {
                this.loading = true;
                await this.$http.put('/api/v1/vessel/update', {
                    'id': this.vessel_id,
                    'name': this.name,
                    'size': this.size,
                    'registrationPort': this.registrationPort,
                    'landingPort': this.landingPort,
                    'productionYear': this.productionYear,
                    'shipOwnerOrigin': this.shipOwnerOrigin,
                    'wppOperation': this.wppOperation,
                    'catchingTool': this.catchingTool,
                    'machineType': this.machineType,
                    'palkaCapacity': this.palkaCapacity,
                    'abkNumber': this.abkNumber,
                    'status': this.status,
                    'createdAt': '',
                    'modifiedAt': ''
                }, {
                    params: {
                        vessel_id: this.vessel_id,
                    }
                }).then(() => {
                    // handle success
                    this.$router.push({ name: 'vesselIndex' })
                    this.loading = false;
                })
                    .catch((error) => {
                        console.log(error)
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            validate() {
                this.$refs.form.validate()
                console.log(this.valid)
                if (this.valid == true) {
                    this.save()
                }
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation();
                this.updateProcess = false;
            },

        }
    }
</script>