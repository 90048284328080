<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <my-table :data="data" :headers="headers"></my-table>
        <v-dialog v-if="itemSelected" v-model="dialog" persistent max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    {{itemSelected.buildManufacturer}} {{ itemSelected.buildModel }}
                </v-card-title>
                <v-card-text>Apakah setuju {{ itemSelected.buildManufacturer }} sebagai alat capture baru?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" text @click="dialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="btnNormal" text @click="onClickApprove(itemSelected)">
                        Approve
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <snackbar :color="snackColor" :message="snackMessage" :show="showSnack"></snackbar>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    import Snackbar from "@/components/Overlay.vue";
    import MyTable from "@/components/DataTable.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            MyTable,
            Snackbar
        },
        data() {
            return {
                title: "Device - Daftar Data",
                description: "Keseluruhan Data Device yang Terdaftar",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "Device",
                        disabled: true,
                        href: "/device",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                headers: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: true,
                        value: 'id',
                    },
                    { text: 'Device Model', value: 'buildModel' },
                    { text: 'Manufacture', value: 'buildManufacturer' },
                    { text: 'APK Version', value: 'apkVersion' },
                    { text: 'TF Model', value: 'tensorflowModel' },
                    { text: 'Kapal', value: 'vessel.name' },
                    { text: 'Created At', value: 'createdAt' },
                ],
                data: [],
                dialog: false,
                itemSelected: null
            }
        },
        methods: {
            onClickNewSpecies() {
                console.log("new species")
                this.$router.push({ name: 'speciesUpload' })
            },
            onClickEditItem(item) {
                console.log(item)
                this.itemSelected = item
                this.dialog = true
            },
            onClickArchiveItem(v) {
                console.log(v)
            },
            loadData() {
                this.loading = true;
                this.$http.get('api/v1/device/list')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            onClickApprove(item) {
                this.dialog = false
                this.$http.patch('/devices/approval', { id: item.id, status: "approved" })
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                    })
                    .catch((error) => {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }
        },
        created() {
            this.loadData();
        }
    }
</script>