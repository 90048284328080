<template>
  <v-container fluid class="pa-7">
    <overlay :loading="loading"></overlay>
    <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
    <v-row>
      <v-col cols="12" md="10">
        <v-autocomplete outlined dense label="Species" auto-select-first clearable v-model="species" :items="speciesA"
          item-text="name" return-object></v-autocomplete>
      </v-col>
      <v-col cols="12" md="10">
        <v-file-input outlined dense v-model="files" chips show-size multiple label="Images" prepend-icon=""
          prepend-inner-icon="mdi-image"></v-file-input>
      </v-col>
      <v-col cols="12" md="10" class="d-flex justify-end">
        <v-btn :loading="loading" :disabled="loading" color="primary" class="ma-2" @click="onClickUpload">
          Upload
          <v-icon right dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>



  </v-container>
</template>
<script>
  import MyPageHeader from "@/components/PageHeaders.vue";
  import Overlay from "@/components/Overlay.vue";
  export default {
    components: {
      MyPageHeader,
      Overlay
    },
    data() {
      return {
        loader: null,
        title: "Data Training - Input Data",
        description: "Input Data Training",
        breadcrumbs: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Data Training",
            disabled: false,
            href: "/training",
          },
          {
            text: "Input",
            disabled: true,
            href: "/training/upload",
          },
        ],
        loading: false,
        snackColor: "success",
        snackMessage: "",
        showSnack: false,
        label: "",
        files: [],
        speciesA: [],
        species: null,

      }
    },
    methods: {
      onClickUpload() {
        let formData = new FormData();
        formData.append("species_id", this.species.id);
        formData.append("name", this.species.name);
        formData.append("status", 'Unarchive');
        formData.append("user_id", localStorage.userId);
        this.files.forEach(function (value) {
          formData.append("rawImage", value);
        })

        this.$http.post('api/v1/training/create', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
          .then((response) => {
            // handle success
            console.log(response);
            this.$router.push({ name: 'trainingIndex' })
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      },
      loadSpecies() {
        this.$http.get('api/v1/species/list')
          .then((response) => {
            // handle success
            this.speciesA = response.data.data
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      }
    },
    created() {
      this.loadSpecies();
    }
  }
</script>