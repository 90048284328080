<template>
    <div class="not-found-page">
        <h1 class="error-code">404</h1>
        <h2 class="error-message">Oops! Page Not Found</h2>
        <p class="error-description">
            The page you are looking for doesn't exist. Try going back to the previous page or contact the website
            administrator.
        </p>
        <v-row>
            <v-col class="d-flex">
                <button class="back-button" @click="$router.go(-1)">Back</button>
                <button class="home-button" @click="$router.push('/home')">Home</button>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: "NotFoundPage",
    };
</script>

<style scoped>
    .not-found-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        text-align: center;
    }

    .error-code {
        font-size: 6rem;
        font-weight: bold;
        margin-bottom: 2rem;
    }

    .error-message {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    .error-description {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .back-button,
    .home-button {
        padding: 0.75rem 2rem;
        margin: 1rem;
        font-size: 1.5rem;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .back-button:hover,
    .home-button:hover {
        background-color: #0056b3;
    }
</style>