<template>
  <v-container fluid class="pa-7">
    <overlay :loading="loading"></overlay>
    <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
    <!-- <my-button :color="'primary'" :onClick="downloadImage" :tooltip="'download image test'"
      :label="'Download'"></my-button> -->
    <v-row>
      <v-col cols="6">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="selectedDate" label="Search by Date" outlined clearable prepend-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on" class="mb-4"></v-text-field>
          </template>
          <v-date-picker v-model="selectedDate" no-title @input="menu = false"
            @clear="selectedDate = null"></v-date-picker>
        </v-menu>
      </v-col>

    </v-row>
    <my-table :data="data" :headers="headers" :editable="editable" :formatPercentage="formatPercentage"
      :showDetail="showDetail" :showDetailFunction="showDetailFunction" :onClickVerify="onClickIdentify"
      :deletable="deletable" :onClickDeleteItem="onClickDeleteItem" :selectedDate="selectedDate"></my-table>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
      <template>
        <v-card>
          <v-toolbar class="" :color="'primary'" style="color: white;">Details</v-toolbar>
          <v-img class="white--text align-start" contain
            :src="baseImageUrl + '/images/prediction/'+ this.predictionDetail.image"></v-img>
          <v-card-text class="ma-4">
            <div class="text-subtitle-1">Species: {{ predictionDetail.species }}</div>
            <div class="text-subtitle-1">Confidence: {{ formatPercentage(predictionDetail.precision) }}</div>
            <!-- <div class="d-flex justify-end"
              v-if="predictionDetail.status === 'Unidentified' || predictionDetail.status === 'draft'">
              <my-button :color="'primary'" :onClick="onClickIdentify" :tooltip="'Verify Prediction'"
                :label="'Verify'"></my-button>
            </div> -->
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="confirmDialogShow" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Identify prediction?
        </v-card-title>
        <v-card-text>

          <v-container>
            <v-row>
              This action updates the prediction status to "verified" and copies the image to the 'Train'
              folder.
            </v-row>
            <v-row class="mt-8">
              <v-autocomplete outlined dense label="Species" auto-select-first clearable v-model="species"
                :items="speciesData" item-text="species" return-object></v-autocomplete>
            </v-row>
            <v-row>
              <v-text-field outlined dense v-model="size" label="Size" hide-details="auto"></v-text-field>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDialogShow = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="updateStatus">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteShow" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Delete prediction data?
        </v-card-title>
        <v-card-text>

          <v-container>
            <v-row>
              This action will remove the prediction data and its image permanently.
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDeleteShow = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="deletePrediction">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex';
  import MyPageHeader from "@/components/PageHeaders.vue";
  import Overlay from "@/components/Overlay.vue";
  import MyTable from "@/components/DataTable.vue";
  // import MyButton from "@/components/Button.vue";
  export default {
    components: {
      MyPageHeader,
      Overlay,
      MyTable,
      // MyButton,
    },
    data() {
      return {
        title: "Identification Result",
        description: "All Stored Identification Data",
        breadcrumbs: [],
        loading: false,
        snackColor: "success",
        snackMessage: "",
        predictionId: null,
        selectedDate: null,
        showSnack: false,
        editable: false,
        deletable: true,
        predictionDetail: {},
        showDetail: true,
        dialog: false,
        menu: false,
        confirmDialogShow: false,
        confirmDeleteShow: false,
        baseImageUrl: process.env.VUE_APP_HOST,
        headers: [
          // {
          //   text: 'Device',
          //   align: 'start',
          //   sortable: false,
          //   value: 'device.buildModel',
          // },
          // { text: 'Kapal', value: 'vessel.name' },
          // { text: 'Tanggal', value: 'captureAt' },
          { text: 'Species', value: 'species' },
          { text: 'Date', value: 'capture_at' },
          { text: 'Confidence', value: 'precision' },
          // { text: 'Width (cm)', value: 'width' },
          // { text: 'Height (cm)', value: 'height' },
          { text: 'Location', value: 'location' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        species: null,
        size: null,
        data: [],
        speciesData: [],
      }
    },
    computed: {
      ...mapGetters(['predictionData', 'vessel', 'registrationPort']),
      dynamicBreadcrumbs() {
        return [
          {
            text: "Dashboard",
            disabled: false,
            href: "/home"
          },
          {
            text: "Area",
            disabled: false,
            href: "/prediction"
          },
          {
            text: this.registrationPort,
            disabled: false,
            href: "/area"
          },
          {
            text: this.vessel.name,
            disabled: false,
            href: "/catching"
          },
          {
            text: this.predictionData.capture_month,
            disabled: true,
            href: "/year"
          },

        ]
      }
    },
    methods: {
      updateSelectedDate() {
        console.log(this.selectedDate)
      },
      async onClickIdentify(item) {
        this.predictionDetail = item;
        this.confirmDialogShow = true;
      },
      async onClickDeleteItem(item) {
        this.predictionId = item.id;
        this.confirmDeleteShow = true;
      },
      async updateStatus() {
        this.loading = true;
        let detail = this.predictionDetail;
        let status = 'verified';
        let species = this.species.species;
        let size = this.size;

        await this.$http.put(`/api/v1/prediction/update/status`, {
          status: status,
          species: species,
          size: size,
        }, {
          params: {
            prediction_id: detail.id,
          },
        })
          .then(() => {
            // handle success
            this.loading = false;
            this.confirmDialogShow = false;
            this.dialog = false;
            this.loadData();
            this.$router.push('/catching');
          })
          .catch((error) => {
            this.loading = false;
            this.confirmDialogShow = false;
            this.dialog = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      async deletePrediction() {
        this.loading = true;

        await this.$http.delete(`/api/v1/prediction/delete`, {
          params: {
            prediction_id: this.predictionId,
          },
        })
          .then(() => {
            // handle success
            this.loading = false;
            this.confirmDeleteShow = false;
            this.$router.push('/catching');
          })
          .catch((error) => {
            this.loading = false;
            this.confirmDeleteShow = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      onClickNewPrediction() {
        this.$router.push({ name: 'predictionForm' })
      },
      async downloadImage() {
        this.loading = true;
        await this.$http
          .get('/api/v1/report/download-image', {
            responseType: 'blob', params: {
              vessel_id: 1
            }
          })
          .then((response) => {
            this.loading = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error) => {
            this.loading = false;
            this.showSnack = true;
            this.snackColor = 'error';
            this.snackMessage = error.response.data.detail;
          });
      },
      loadSpecies() {
        this.loading = true;
        this.$http.get('api/v1/training/image/list')
          .then((response) => {
            // handle success
            this.speciesData = response.data.data
            this.loading = false;
          })
          .catch((error) => {
            // handle error
            this.loading = false;
            this.showSnack = true;
            this.snackColor = "error";
            this.snackMessage = error.response.data.detail;
          })
          .then(function () {
            // always executed
          });
      },
      loadData() {
        this.data = this.predictionData.predictions
      },
      formatPercentage(value) {
        if (value != null) {
          return (value * 100).toFixed(0) + '%';
          // return (value).toFixed(2);
        }
        return '';
      },
      async showDetailFunction(item) {
        this.predictionDetail = item;
        this.dialog = true;
      },
    },
    created() {
      this.breadcrumbs = this.dynamicBreadcrumbs;
      this.loadData();
      this.loadSpecies();
    }
  }
</script>