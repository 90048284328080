<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-row>
            <v-col v-for="(detail,index) in data" :key="detail" class="d-flex child-flex" cols="3">
                <v-img :src="baseImageUrl + '/images/prediction/'+ detail.image"
                    :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`" aspect-ratio="1" contain
                    class="bg-grey-lighten-2">
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay
        },
        data() {
            return {
                title: "Onboard - Galery",
                description: "Keseluruhan Gambar Onboard yang Tersimpan",
                breadcrumbs: [
                    {
                        text: "galery",
                        disabled: false,
                        href: "/",
                    },
                    // {
                    //     text: "Onboard",
                    //     disabled: true,
                    //     href: "/prediction",
                    // },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
                editable: false,
                predictionDetail: {},
                showDetail: true,
                dialog: false,
                confirmDialogShow: false,
                baseImageUrl: process.env.VUE_APP_HOST,
                headers: [
                    {
                        text: 'Device',
                        align: 'start',
                        sortable: false,
                        value: 'device.buildModel',
                    },
                    { text: 'Kapal', value: 'vessel.name' },
                    { text: 'Species', value: 'species' },
                    { text: 'Precision', value: 'precision' },
                    { text: 'Location', value: 'location' },
                    { text: 'Status', value: 'status' },
                    { text: 'Actions', value: 'actions', sortable: false }
                ],
                data: []
            }
        },
        methods: {
            loadData() {
                this.loading = true;
                this.$http.get('/api/v1/prediction/list')
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            formatPercentage(value) {
                if (value != null) {
                    return (value).toFixed(2) + '%';
                }
                return '';
            },
            showDetailFunction(item) {
                this.predictionDetail = item;
                this.dialog = true;
            },
        },
        created() {
            this.loadData();
        }
    }
</script>