<template>
    <v-container fluid class="pa-7">
        <snackbar :color="snackColor" :message="snackMessage" :show="showSnack" :btnCloseClick="snackCloseBtn">
        </snackbar>
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-data-table :headers="headers" :items="data" :items-per-page="5" hide-default-footer class="elevation-1">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id" @click="handleRowClick(item)" style="cursor: pointer;">
                        <td>
                            <v-icon color="black">mdi-folder</v-icon> {{ item.name }}
                        </td>
                        <td>{{ item.last_modified }}</td>
                        <td></td>
                        <td>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn plain color="black" v-bind="attrs" v-on="on"
                                        @click.stop="handleDownload(item, 1)">
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <span>Download Image</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn plain color="black" v-bind="attrs" v-on="on"
                                        @click.stop="handleDownload(item, 2)">
                                        <v-icon>mdi-microsoft-excel</v-icon>
                                    </v-btn>
                                </template>
                                <span>Download Excel</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    import Snackbar from "@/components/Snackbar.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay,
            Snackbar
        },
        data() {
            return {
                title: "Identification Result",
                description: "All Stored Identification Data",
                breadcrumbs: [],
                loading: false,
                headers: [
                    { text: 'Vessel', value: 'name' },
                    { text: 'Last Modified', value: '' },
                    { text: 'Size', value: '' },
                    { text: '', value: '' },

                ],
                data: [],
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
            }
        },
        computed: {
            ...mapGetters(['registrationPort']),
            dynamicBreadcrumbs() {
                return [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/home"
                    },
                    {
                        text: "Area",
                        disabled: false,
                        href: "/prediction"
                    },
                    {
                        text: this.registrationPort,
                        disabled: true,
                        href: "/area"
                    }
                ]
            }
        },
        methods: {
            handleRowClick(item) {
                this.$store.dispatch('setVessel', item)
                this.$router.push('/catching');
            },
            loadData() {
                this.breadcrumText = this.registrationPort
                this.loading = true;
                this.$http.get('/api/v1/report/vessel-by-registration-port', {
                    params: {
                        registration_port: this.registrationPort
                    }
                })
                    .then((response) => {
                        // handle success
                        this.data = response.data.data
                        this.loading = false;
                    })
                    .catch((error) => {
                        // handle error
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                    })
                    .then(function () {
                        // always executed
                    });
            },
            async handleDownload(item, type) {
                this.loading = true;
                if (type == 1) {
                    await this.$http
                        .get('/api/v1/report/download-image-by-vessel', {
                            responseType: 'blob', params: {
                                vessel_id: item.id
                            }
                        })
                        .then((response) => {
                            this.loading = false;
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'image.zip');
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch(() => {
                            this.loading = false;
                            this.showSnack = true;
                            this.snackColor = 'error';
                            this.snackMessage = "Data Not Found";
                        });
                } else {
                    await this.$http
                        .get('/api/v1/report/prediction-vessel/export', {
                            responseType: 'blob',
                            params: {
                                vessel_id: item.id,
                            }
                        })
                        .then((response) => {
                            this.loading = false;
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'Prediction Result.xlsx');
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch(() => {
                            this.loading = false;
                            this.showSnack = true;
                            this.snackColor = 'error';
                            this.snackMessage = "Data Not Found";
                        });
                }

            },
            snackCloseBtn() {
                this.showSnack = false
            }
        },
        created() {
            this.breadcrumbs = this.dynamicBreadcrumbs;
            this.loadData();
        }
    }
</script>