export const nameRules = [
    (v) => !!v || "This is required field"
  ];
  
export const emailRules = [
    (v) => !!v || "Email is required",
    (v) => /.+@.+\..+/.test(v) || "Email must be valid",
];

export const mustNumber = [
    (v) => !!v || "Field is required",
    (v) => !isNaN(parseFloat(v)) && isFinite(v) || 'Quantity must be a number',
];
  