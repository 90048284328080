<template>
    <div class="d-flex flex-md-row flex-column justify-md-space-between align-md-center mb-6">
        <div class="d-flex flex-column">
            <div class="text-md-h6 text-subtitle-1">
                {{ title }}
            </div>
            <div class="text-md-subtitle-1 text-caption font-weight-thin">
                {{ description }}
            </div>
        </div>

        <my-breadcrumbs :items="breadcrumbs" />
    </div>
</template>

<script>
    import Mybreadcrumbs from "./Breadcrumbs.vue";
    export default {
        components: {
            'my-breadcrumbs': Mybreadcrumbs
        },
        name: "myPageHeader",
        props: {
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            breadcrumbs: {
                type: Array,
                required: true
            }
        }
    }
</script>