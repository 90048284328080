<template>
    <v-container fluid class="fill-height" v-if="this.data != null">
        <v-row>
            <v-col cols="12">
                <v-card class="d-flex child-flex">
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Training Detail
                            </div>
                            <v-list-item-title class="text-h6 mb-1">
                                {{ this.data.species.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ this.data.processStatus }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(rawImage, index) in data.rawImages" :key="index" class="d-flex child-flex" cols="4">
                <v-card class="pa-1 ma-1">
                    <v-img max-height="125" contain :src="'http://localhost:3060/' + rawImage.url"></v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            trainingId: null,
            data: null
        }
    },
    methods: {
        loadData() {
            this.$http.get('/training_uploads', { params: { id: this.trainingId } })
                .then((response) => {
                    // handle success
                    this.data = response.data.data
                })
                .catch((error) => {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    },
    created() {
        this.trainingId = this.$route.query.id;
    },
    watch: {
        trainingId(newData, oldData) {
            if (newData != oldData) {
                this.loadData();
            }

        }
    }
}
</script>
