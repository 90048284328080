<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="color" :class="btnClass" @click="onClick" :disabled="disabled" v-bind="attrs" v-on="on">
                <v-icon dark> {{ icon }} </v-icon>
                {{ label }}
            </v-btn>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: "myButton",
        props: {
            disabled: Boolean,
            color: String,
            label: String,
            icon: String,
            tooltip: String,
            onClick: Function,
            btnClass: String,
        },
    };
</script>

<style scoped>
    .v-btn {
        margin: 5px;
    }
</style>