<template>
    <v-app id="inspire">
        <v-content>
            <overlay :loading="loading"></overlay>
            <v-container fluid fill-height class="gradient">
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4 align="center">
                        <v-alert icon="mdi-alert" type="error" :value="alert"> {{ alertText }}</v-alert>
                        <v-card class="elevation-2 pa-4 login-card" align="center" max-width="100vw">
                            <div class="text-h4"><b class="ifip">IFIP</b></div>
                            <div class="text-subtitle gray-text">(Integrated Fisheries Identification Platform)</div>
                            <div class="text-h6 text-primary mt-8">Login </div>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-text-field outlined id="email" v-model="email" :rules="emailRules" name="login"
                                        label="Username" type="text" style="font-size: 16px"></v-text-field>
                                    <v-text-field outlined id="password" v-model="password" :rules="passwordRules"
                                        name="password" label="Password"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword = !showPassword"
                                        :type="showPassword ? 'text' : 'password'"
                                        style="font-size: 16px"></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end">
                                <v-btn color="primary" @click="validate" :loading="loading"
                                    style="font-size: 16px; border-radius: 5px"
                                    :class="{'v-btn--block': $vuetify.breakpoint.smAndDown}" class="btn-grad">
                                    Login
                                </v-btn>
                            </v-card-actions>
                            <div class="text-subtitle gray-text">Powered by</div>
                            <v-img class="my-4" max-width="100"
                                :src="require('@/assets/IntelionLogo-LandscapeColor.png')"></v-img>
                        </v-card>
                    </v-flex>
                    <v-img class="image-bottom-right" max-width="1000"
                        :src="require('@/assets/Kementerian Kelautan dan Perikanan.png')"></v-img>
                </v-layout>

                <snackbar :color="snackColor" :message="snackMessage" :show="showSnack" :btnCloseClick="btnCloseClick">
                </snackbar>
            </v-container>
        </v-content>
    </v-app>
</template>
<style scoped>
    .kkp {
        background-image: url("../assets/Kementerian\ Kelautan\ dan\ Perikanan.png");
        background-size: contain;
        background-repeat: no-repeat;
    }

    .login-card {
        opacity: 92%;
        /* width: 90%; */
        min-height: 65vh;
    }

    .v-card__text,
    .v-card__actions {
        width: 75%;
    }

    .gray-text {
        color: gray;
    }

    .image-bottom-right {
        position: fixed;
        top: 7vh;
        left: 100vh;
        z-index: 0;
    }

    @media (max-width: 1080px) {
        .image-bottom-right {
            left: 1vh;
            right: 0.1vh;
            bottom: 0;
            /* top: auto; */
        }
    }

    .v-card {
        z-index: 1;
        border-radius: 10px;
    }
</style>


<script>
    import Snackbar from "@/components/Snackbar.vue";
    import Overlay from "@/components/Overlay.vue";
    export default {
        components: {
            Snackbar,
            Overlay
        },
        data: () => ({
            valid: true,
            loading: false,
            showPassword: false,
            email: "info@intelion.co.id",
            password: "qwerty",
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required'
            ],
            alert: false,
            alertText: "",
            snackColor: "success",
            snackMessage: "",
            showSnack: false,

        }),
        methods: {
            login() {
                this.loading = true;
                // this.$router.push({ name: 'home' })
                this.$http.post('/api/v1/user/login', {
                    'email': this.email,
                    'password': this.password
                })
                    .then((response) => {
                        if (response.status == 200) {
                            console.log(response)
                            // TODO: put token to localstorage
                            localStorage.token = response.data.data.accessToken;
                            localStorage.name = response.data.data.name;
                            localStorage.userId = response.data.data.userId;
                            localStorage.expireAt = response.data.data.expiredAt;
                            this.$router.push({ name: 'home' })
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.showSnack = true;
                        this.snackColor = "error";
                        this.snackMessage = error.response.data.detail;
                        if (error.response.status == 401) {
                            this.alertText = error.response.statusText
                            this.alert = true
                            this.$refs.form.resetValidation()
                        }
                        if (error.response.status == 422) {
                            this.alertText = error.response.data.error
                            this.alert = true
                        }

                    });
            },
            validate() {
                this.$refs.form.validate()
                if (this.valid == true) {
                    this.login()
                }
            },
            btnCloseClick() {
                this.showSnack = false;
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
        }
    }
</script>