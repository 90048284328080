<template>
    <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item @click="routerPush(item.href)" :disabled="item.disabled" class="breadcrumb-link">
                {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
    export default {
        name: "MyBreadcrumbs",
        props: {
            items: {
                type: Array,
                required: true,
            },
        },
        methods: {
            routerPush(path) {
                this.$router.push(path);
            },
        },
    };
</script>

<style scoped>
    .v-application ul,
    .v-application ol {
        padding-left: 0px;
    }

    .breadcrumb-link {
        cursor: pointer;
    }
</style>