<template>
    <v-overlay :value="loading">
        <v-progress-circular indeterminate size="100">
            <img class="progress-image" src="../assets/ifip logo.png" alt="Image" />
        </v-progress-circular>
    </v-overlay>
</template>
<script>
    export default {
        name: "MyOverlay",
        props: {
            loading: {
                type: Boolean,
                // required: true,
            },
        }
    };
</script>

<style>
    .progress-image {
        width: 100px;
        height: 100px;
    }
</style>