import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    registration_port: null,
    vessel: null,
    prediction_data: []
  },
  mutations: {
    setRegistrationPort(state, port) {
        state.registration_port = port;
    },
    setVessel(state, vessel) {
        state.vessel = vessel
    },
    setPredictionData(state, prediction) {
        state.prediction_data = prediction
    }
  },
  actions: {
   setRegistrationPort({ commit }, port) {
    commit('setRegistrationPort', port);
   },
   setVessel({ commit }, vessel) {
    commit('setVessel', vessel)
   },
   setPredictionData({ commit }, prediction) {
    commit('setPredictionData', prediction)
   }
  },
  getters: {
   registrationPort: state => state.registration_port,
   vessel: state => state.vessel,
   predictionData: state => state.prediction_data
  }
});

// Save state to local storage whenever state changes
store.subscribe((mutation, state) => {
  localStorage.setItem('vuex_state', JSON.stringify(state));
});

// Initialize state from local storage when Vuex store is created
const savedState = JSON.parse(localStorage.getItem('vuex_state'));
if (savedState) {
  store.replaceState(savedState);
}

export default store;