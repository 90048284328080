<template>
    <v-container fluid class="pa-7">
        <overlay :loading="loading"></overlay>
        <my-page-header :title="title" :description="description" :breadcrumbs="breadcrumbs"></my-page-header>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col cols="12" md="10">
                    <v-text-field outlined dense v-model="name" :counter="30" :rules="nameRules" label="Name" required>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="10">
                    <v-text-field outlined dense v-model="email" :rules="emailRules" label="E-mail"
                        required></v-text-field>
                </v-col>
                <v-col cols="12" md="10">
                    <v-text-field outlined dense v-model="password" :rules="passwordRules" label="Password" required>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" class="d-flex justify-end">
                    <v-btn color="primary" class="mr-4 mt-4" @click="validate">
                        Save
                    </v-btn>

                    <v-btn color="btnCancel" class="mr-4 mt-4" @click="resetValidation">
                        Cancel
                    </v-btn>
                </v-col>
            </v-row>

        </v-form>
    </v-container>
</template>
<script>
    import MyPageHeader from "@/components/PageHeaders.vue";
    import Overlay from "@/components/Overlay.vue";
    export default {
        components: {
            MyPageHeader,
            Overlay
        },
        data() {
            return {
                valid: true,
                name: '',
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 30) || 'Name must be less than 30 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                password: null,
                passwordRules: [
                    v => !!v || 'Password is required',
                    v => (v && v.length >= 6) || 'Password min 6 characters',
                ],
                title: "Species - Input Data",
                description: "Input Data Spesies",
                breadcrumbs: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: "User",
                        disabled: false,
                        href: "/user",
                    },
                    {
                        text: "Input",
                        disabled: true,
                        href: "/user/new",
                    },
                ],
                loading: false,
                snackColor: "success",
                snackMessage: "",
                showSnack: false,
            }
        },
        methods: {
            save() {
                this.loading = true;
                this.$http.post('api/v1/user/register', {
                    'name': this.name,
                    'email': this.email,
                    'password': this.password,
                    'confirmPassword': this.password
                })
                    .then((response) => {
                        console.log(response);
                        this.$router.push({ name: 'userIndex' })
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                    });
            },
            validate() {
                this.$refs.form.validate()
                console.log(this.valid)
                if (this.valid == true) {
                    this.save()
                }
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },

        }
    }
</script>