<template>
    <div style="width: 100%">
        <v-data-table hide-default-footer :headers="headers" :items="paginatedData" class="elevation-1"
            :items-per-page="pagination.perPage" :options.sync="pagination">
            <template v-slot:item.species="{ item }">
                <template v-if="item.image">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <div class="flex-container">
                                <v-icon small v-on="on" style="margin-right: 5px;">
                                    mdi-image
                                </v-icon>
                                <div>{{ item.species }}</div>
                            </div>
                        </template>
                        <span>Image available</span>
                    </v-tooltip>
                </template>
                <template v-else>
                    <div style="margin-left: 22px;">
                        {{ item.species }}
                    </div>
                </template>
            </template>
            <template v-slot:item.precision="{ item }">
                {{ formatPercentage(item.precision) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>
                    <v-list>
                        <v-list-item v-if="editable" @click="onClickEditItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.status === 'Archive'" @click="onClickArchiveItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-archive-cancel
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Unarchive</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.status === 'Unarchive'" @click="onClickArchiveItem(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-archive
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Archive</v-list-item-title>
                        </v-list-item>
                        <v-list-item :disabled="item.status === 'verified' || item.status === 'Identified'"
                            @click="onClickVerify(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-archive
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Verify</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="showDetail" @click="showDetailFunction(item)">
                            <v-list-item-icon>
                                <v-icon small>
                                    mdi-image-text
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>View</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="deletable" @click="onClickDeleteItem(item)">
                            <v-list-item-icon>
                                <v-icon small color="red">
                                    mdi-delete
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title color="red">Delete</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <div class="text-center ma-4">
            <v-pagination v-model="currentPageLocal" :length="totalPageCount"
                :total-visible="visiblePages"></v-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "myDataTable",
        props: {
            data: {
                type: Array,
                required: false,
            },
            headers: {
                type: Array,
                required: true,
            },
            editable: Boolean,
            deletable: Boolean,
            showDetail: Boolean,
            loading: Boolean,
            onClickEditItem: Function,
            onClickDeleteItem: Function,
            onClickVerify: Function,
            showDetailFunction: Function,
            formatPercentage: Function,
            currentPage: {
                type: Number,
                default: 1,
            },
            totalPages: {
                type: Number,
                default: 1,
            },
            visiblePages: {
                type: Number,
                default: 5, // Default visible pages in pagination
            },
            selectedDate: {
                type: String, // Change type as per your date format
                default: null,
            },
        },
        data() {
            return {
                baseImageUrl: process.env.VUE_APP_HOST,
                currentPageLocal: this.currentPage,
                pagination: {
                    enabled: true,
                    totalItems: this.data.length,
                    perPage: 20,
                },
            };
        },
        methods: {
            getImageUrl(imageName) {
                return this.baseImageUrl + '/images/prediction/' + imageName;
            },
        },
        computed: {
            paginatedData() {
                let filteredData = this.data;
                if (this.selectedDate) {
                    filteredData = filteredData.filter(item => {
                        const captureDate = item.capture_at.split(" ")[0];
                        return captureDate === this.selectedDate;
                    });
                }

                const start = (this.currentPageLocal - 1) * this.pagination.perPage;
                const end = start + this.pagination.perPage;
                return filteredData.slice(start, end);
            },
            totalPageCount() {
                if (this.selectedDate) {
                    return Math.ceil(this.paginatedData.length / this.pagination.perPage);
                } else {
                    return Math.ceil(this.data.length / this.pagination.perPage);
                }

            },
        },
        watch: {
            data: {
                handler() {
                    // If there's no filter applied, update totalItems to whole data length
                    if (!this.selectedDate) {
                        this.pagination.totalItems = this.data.length;
                    }
                    this.currentPageLocal = this.currentPage;
                },
                deep: true,
            },
            currentPageLocal() {
                this.$emit('update:currentPage', this.currentPageLocal);
            },
            totalPages() {
                this.pagination.totalItems = this.totalPageCount;
                this.currentPageLocal = this.currentPage;
            },
        },
    };
</script>

<style scoped>
    .flex-container {
        display: flex;
        align-items: left;
    }

    .v-responsive {
        max-width: 10%;
    }
</style>