import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
    theme: { themes: {
        light: {
          primary: "#5567A8",
          // primary: "#2196F3",
          secondary: "#7E57C2",
          accent: "#7E57C2",
          btnNormal: "#9CCC65",
          btnCancel: "#EEEEEE",
          success: "#9CCC65",
          error: "#EF5350",
          black: colors.black
        },
        dark: {
          primary: colors.grey,
          secondary: colors.grey.darken1,
          accent: colors.shades.black,
          btnNormal: "#9CCC65",
          btnCancel: "#EEEEEE",
          success: colors.green.darken3,
          error: colors.red.accent3,
          black: colors.black
        },
      },
      dark: false },
    
});
