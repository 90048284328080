<template>
    <v-container>
        <v-select label="Language" :items="langs"></v-select>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            langs: ["English", "Indonesia"]
        }
    }
}
</script>