<template>
    <v-snackbar :timeout="-1" :value="show" :color="color" outlined>
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="btnCloseClick">
                Close
            </v-btn>
        </template>
        {{ message }}
    </v-snackbar>
</template>

<script>
    export default {
        name: "mySnackbar",
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            color: {
                type: String,
                default: "success",
            },
            message: {
                type: String,
            },
            btnCloseClick: Function
        },
    };
</script>